import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	MenuItem,
	Typography,
	LinearProgress,
	useMediaQuery,
	Breadcrumbs,
	FormControl,
	InputLabel,
	Select,
	RadioGroup,
	Radio,
	FormControlLabel,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridSelectionModel,
	GridRowModel,
	GridDensity,
	useGridApiRef,
	GridCellEditStopParams,
	GridEventListener,
	GridRowParams,
	MuiEvent,
	GridRowModesModel,
	GridRowsProp,
	GridToolbar,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import { selectuserInfo } from 'hooks/userInfo'
import { getSettings } from 'helpers/storage'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import {
	alertModal,
	compareDate,
	confirmAlert,
	hexToRgba,
	isValidDateType,
	timerAlert,
} from 'util/util'

import {
	apiSBReceptionList,
	apiSBList,
	apiSBReserve,
	apiSBReceptionInfoList,
	apiReceptionSubList,
} from 'api/api'

import { PageBg } from 'components/ComponentStyles'

import moment from 'moment'
import 'moment/locale/ko'
import DownloadIcon from '@mui/icons-material/Download'
import SlideBlockPopup2 from 'components/SlideBlockPopup2'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const locales = ['ko'] as const

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const SearchRowItem = styled(Box)(({ theme }) => ({
	position: 'relative',
	display: 'block',
	paddingLeft: '80px',
	minHeight: '44px',
	marginBottom: '10px',
	'&.noLabel': {
		paddingLeft: '0',
	},
	'.labelText': {
		position: 'absolute',
		left: '10px',
		top: '15px',
		[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
			position: 'relative',
			left: '0',
			top: '0',
			marginBottom: '0.5rem',
		},
	},
	[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
		paddingLeft: '0',
		height: 'auto',
	},
}))

const SbRequestPage = () => {
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

	const userInfo = useSelector(selectuserInfo)

	const env = getSettings()

	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	// ui state
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)

	const [dateRange, setDateRange] = useState('1d')

	const [search, setSearch] = useState({
		clientID: userInfo.ClientID,
		chartNo: '',
		patientName: '',
		socialNumber: '',
		pathologyNo: '',
		status: '0',
		step: '0',
		startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		searchType: 'D',
	})

	const actionReset = () => {
		setSearch({
			clientID: userInfo.ClientID,
			chartNo: '',
			patientName: '',
			socialNumber: '',
			pathologyNo: '',
			status: '0',
			step: '0',
			startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			searchType: 'R',
		})
		setPopupData({})
		setSelectedSbList([])
	}

	const actionSearch = () => {
		console.table(search)

		// 내부사용자이고 clientID가 없으면
		if (
			(userInfo.infoType === 'S' || userInfo.infoType === 'W') &&
			search.clientID === ''
		) {
			alertModal('거래처를 선택해주세요.', 'error', () => {})
			return
		}

		if (moment(search.startDate).isBefore('2023-08-01')) {
			setSearch({
				...search,
				startDate: '2023-08-01',
			})
		}

		if (search.socialNumber) {
			if (search.socialNumber.length !== 8) {
				alertModal('주민번호는 뒤 1자리까지 입력하세요.', 'error', () => {})
				return
			} else {
				const regexp = /^\d{6}-[1-8]$/g
				if (!regexp.test(search.socialNumber)) {
					alertModal('주민번호 형식이 올바르지 않습니다.', 'error', () => {})
					return
				}
			}
		}

		const request = {
			clientID: search.clientID as any,
			patientName: search.patientName,
			chartNo: search.chartNo,
			startDate: moment(search.startDate).isBefore('2023-08-01')
				? '2023-08-01'
				: search.startDate,
			endDate: search.endDate,
			socialNumber: search.socialNumber,
			pathologyNo: search.pathologyNo,
			status: search.status,
			step: search.step,
			searchType: search.searchType,
		}

		console.log(request)

		setGridMstLoading(true)

		apiReceptionSubList(request)
			.then((res) => {
				console.log(res)
				setRows(res)
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				setGridMstLoading(false)
			})
	}

	const [sbList, setSbList] = useState([])
	const [selectedSbList, setSelectedSbList] = useState<any[]>([])

	// grid state
	const mstRef = useGridApiRef()
	const [rows, setRows] = useState([])
	const [gridMstLoading, setGridMstLoading] = useState(false)
	const [gridMstPageSize, setGridMstPageSize] = useState(env.row ? env.row : 100)

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		setSearchClientName(e.ClientName)
		setSearch({
			...search,
			clientID: e.ClientID,
		})
		setClientDialogOpen(false)
		const request = {
			...search,
			ClientID: e.ClientID,
		}
	}

	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

	const [editRows, setEditRows] = useState<GridRowsProp>([])
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])

	/**
	 * 그리드 체크박스
	 * @param rows
	 */
	const selectChangeHandler = (rows: GridSelectionModel) => {
		setSelectionModel((prevState) => [...rows])
		console.log(rows)
		if (rows.length > 0) {
			setDisabledSave(false)
			setDisabledDel(false)
		} else {
			setDisabledSave(true)
			setDisabledDel(true)
		}
	}

	const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
		console.log(newRow)
		console.log('processRowUpdate')
		return newRow
	}, [])

	const handleRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>
	) => {
		event.defaultMuiPrevented = true
		console.log('start', event, params)
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event
	) => {
		event.defaultMuiPrevented = true
		console.log('end', event, params)
	}

	const onCellEditStop = (params: GridCellEditStopParams, event: MuiEvent) => {
		const { row } = params
		setEditRows((prevState) => {
			return [...prevState, { ...row }]
		})
	}

	const onStateChange = (params: any) => {}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '1d':
				getBeforeDate(1)
				break
			case '3d':
				getBeforeDate(3)
				break
			case '7d':
				getBeforeDate(7)
				break
			default:
				break
		}
		setDateRange(event.target.value)
		setDisabledSearch(false)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setSearch({
				...search,
				startDate: '2023-08-01',
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, search.endDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, search.startDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				setSearch((prevState) => {
					return {
						...search,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	const actionReserve = (row: any) => {
		console.log(row)
		if (row?.ReceptionID) {
			setPopupData({
				...row,
			})
			setShowPopup(true)
		}
	}

	const actionDownload = () => {
		const a = document.createElement('a')
		a.href = '/원본조직블럭_세포슬라이드대출확인서.jpeg'
		a.download = '원본조직블럭_세포슬라이드대출확인서.jpeg'
		a.click()
		setTimeout(() => {
			a.remove()
		}, 100)
	}

	const [popupData, setPopupData] = useState<any>({})
	const [showPopup, setShowPopup] = useState(false)
	const onPopupClose = () => {
		setShowPopup(false)
		setPopupData({})
		setSelectedSbList([])
	}
	const onPopupSubmit = () => {
		console.log(popupData)
		console.log(selectedSbList)
		if (selectedSbList.length === 0) {
			alertModal('Slide/Block을 선택해주세요.', 'error', () => {})
			return
		}
		if (!popupData?.PatientName) {
			alertModal('수진자명이 없습니다.', 'error', () => {})
			return
		}
		if (!popupData?.PathologyNo) {
			alertModal('병리번호가 없습니다.', 'error', () => {})
			return
		}
		let isError = false
		let tmpReceptionID = 0
		let tmpTestCode = ''
		let tmpPartCode = ''
		let tmpRItemID = 0
		let tmpContainerSName = ''
		let tmpLabCode = ''
		let tmpTeamCode = ''
		let tmpBranchCode = ''
		let tmpManagerID = ''
		if (popupData.PatientName && popupData.PathologyNo) {
			const request = {
				clientID:
					userInfo?.infoType === 'C' ? userInfo?.clientID : search?.clientID,
				pathologyNo: popupData.PathologyNo,
				patientName: popupData.PatientName,
			}
			apiSBReceptionInfoList(request).then((res) => {
				console.log(res.length === 0)
				if (res.length === 0) {
					isError = true
					if (isError) {
						alertModal('일치하는 정보가 않습니다.', 'error', () => {})
						return
					}
					return
				} else {
					tmpReceptionID = res[0].ReceptionID
					tmpTestCode = res[0].TestCode
					tmpPartCode = res[0].PartCode
					tmpRItemID = res[0].RItemID
					tmpContainerSName = res[0].ContainerSName
					tmpLabCode = res[0].LabCode
					tmpTeamCode = res[0].TeamCode
					tmpBranchCode = res[0].BranchCode
					tmpManagerID = res[0].ManagerID

					if (!popupData?.agree) {
						alertModal(
							'"확인했습니다."에 확인 후 신청하실 수 있습니다.',
							'error',
							() => {}
						)
						return
					}
					if (popupData?.agree && selectedSbList.length > 0) {
						confirmAlert(
							'저장하시겠습니까?',
							() => {
								const data: any[] = []
								console.log(selectedSbList)

								const dataObject = {
									ReceptionID: tmpReceptionID,
									ReTestCode: selectedSbList.map((item) => item.TestCode).join(','),
									ClientID:
										userInfo?.infoType === 'C' ? userInfo?.clientID : search?.clientID,
									RequestCode: tmpPartCode,
									RequestNotes: popupData?.RequestNotes || '',
									TRequestType: 'L',
									RItemID: tmpRItemID,
									SContainerSNames: tmpContainerSName,
									BranchCode: tmpBranchCode,
									ManagerID: tmpManagerID,
									LabCode: tmpLabCode,
									TeamCodes: tmpTeamCode,
									TestCode: selectedSbList.map((item) => item.TestCode).join(',') || '',
									RowCrud: 'C',
								}

								data.push(dataObject)

								// selectedSbList 갯수 만큼 request에 추가
								// selectedSbList.forEach((item) => {
								// 	data.push({
								// 		ReceptionID: tmpReceptionID,
								// 		ReTestCode: item.TestCode,
								// 		ClientID:
								// 			userInfo?.infoType === 'C' ? userInfo?.clientID : search?.clientID,
								// 		RequestCode: tmpPartCode,
								// 		RequestNotes: popupData?.RequestNotes || '',
								// 		TRequestType: 'L',
								// 		RItemID: tmpRItemID,
								// 		SContainerSNames: tmpContainerSName,
								// 		BranchCode: tmpBranchCode,
								// 		ManagerID: tmpManagerID,
								// 		LabCode: tmpLabCode,
								// 		TeamCodes: tmpTeamCode,
								// 		TestCode: item.TestCode,
								// 		RowCrud: 'C',
								// 	})
								// })

								const request = {
									clientID:
										userInfo?.infoType === 'C' ? userInfo?.clientID : search?.clientID,
									json: [...data],
								}
								console.log(request)

								if (!request.clientID) {
									alertModal('거래처코드가 없습니다.', 'error', () => {})
									return
								}

								if (request.json.length === 0) {
									alertModal('신청할 Slide/Block을 선택해주세요.', 'error', () => {})
									return
								}

								console.log(request)

								apiSBReserve(request).then((res) => {
									console.log(res)
									timerAlert('신청되었습니다.', 'success', () => {})
									onPopupClose()
									actionSearch()
								})
							},
							() => {}
						)
					}
				}
			})
		}
	}

	const onSBItemClick = (item: any) => {
		// selectedSbList에 동일한 rowNum이 없으면 추가 있으면 삭제
		const index = selectedSbList.findIndex((x) => x.rowNum === item.rowNum)
		if (index !== -1) {
			setSelectedSbList(selectedSbList.filter((x) => x.rowNum !== item.rowNum))
		} else {
			setSelectedSbList([...selectedSbList, item])
		}
	}

	useEffect(() => {
		if (userInfo?.branchCode !== 'B01201') {
			alertModal('접근 권한이 없습니다.', 'error', () => {})
			return
		}
		if (userInfo?.infoType === 'C') actionSearch()
		apiSBList().then((res) => {
			setSbList(res)
			// let resultList: [] = []
			// if (userInfo?.isEhwa) {
			// 	resultList = res.filter((x: any) => x.DisplayName.includes('이화'))
			// } else {
			// 	resultList = res.filter((x: any) => !x.DisplayName.includes('이화'))
			// }
			// setSbList(resultList)
		})
	}, [])

	return (
		<>
			<SlideBlockPopup2
				popupOpen={showPopup}
				popupData={popupData}
				setPopupData={setPopupData}
				selectedSbList={selectedSbList}
				sbList={sbList}
				onPopupClose={onPopupClose}
				onSBItemClick={onSBItemClick}
				onPopupSubmit={onPopupSubmit}
			/>
			<Stack
				sx={{
					position: 'sticky',
					top: '50px',
					zIndex: 100,
					padding: '0.5rem',
					borderRadius: '0.5rem',
					backgroundColor:
						theme.palette.mode === 'dark' ? 'rgba(50, 48, 54, 1)' : '#fff',
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '15px',
						top: '0px',
					},
				}}
			>
				<PageWrap style={{ marginBottom: '0.5rem' }}>
					<PageBg2 sx={{ padding: '0.3rem 0.3rem 0rem 0.5rem' }}>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								Slide/Block 외부반출 신청
							</TitleText>
						</Breadcrumbs>
						<BtnBox>
							<div>
								<span>
									<Button
										size="small"
										color="success"
										variant="contained"
										startIcon={isMdUp && <RotateLeftIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledReset}
										onClick={actionReset}
									>
										초기화
									</Button>
								</span>
								<span>
									<Button
										size="small"
										color="primary"
										variant="contained"
										startIcon={isMdUp && <ContentPasteSearchTwoToneIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledSearch}
										onClick={actionSearch}
									>
										조회
									</Button>
								</span>
								<span>
									<a
										href="/원본조직블럭_세포슬라이드대출확인서.jpeg"
										download
									>
										<Button
											variant="contained"
											color="secondary"
											size="small"
											startIcon={<DownloadIcon />}
											sx={{
												marginRight: '0.5rem',
												padding: isMdUp ? '4px 10px' : '4px 6px',
												minWidth: isMdUp ? '64px' : '50px',
											}}
										>
											확인서 다운로드
										</Button>
									</a>
								</span>
								<span>
									<Button
										variant="contained"
										color="secondary"
										size="small"
										startIcon={<OpenInNewIcon />}
										onClick={() => setShowPopup(true)}
									>
										외부반출 신청
									</Button>
								</span>
							</div>
						</BtnBox>
					</PageBg2>
				</PageWrap>
				<Box
					component="form"
					noValidate
					autoComplete="off"
					onKeyUp={onKeyUp}
					sx={{
						borderTop:
							theme.palette.mode === 'dark'
								? '1px solid rgba(81, 81, 81, 1)'
								: '1px solid #ccc',
						paddingTop: '1rem',
					}}
				>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={locale}
					>
						<SearchRowItem>
							<p className="labelText">조회조건</p>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<FormControl fullWidth>
										<InputLabel id="status-select-label112">검사진행상태</InputLabel>
										<Select
											labelId="status-select-label112"
											id="status-simple-select112"
											label="검사진행상태"
											value={search.step}
											size={'small'}
											onChange={(e) => {
												setSearch({
													...search,
													step: e.target.value,
												})
											}}
										>
											<MenuItem value={'0'}>전체</MenuItem>
											<MenuItem
												value={'1'}
												sx={{ color: '#f39c12' }}
											>
												접수
											</MenuItem>
											<MenuItem
												value={'2'}
												sx={{ color: '#2980b9' }}
											>
												완료
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<FormControl fullWidth>
										<InputLabel id="status-select-label11">반출상태</InputLabel>
										<Select
											labelId="status-select-label11"
											id="status-simple-select11"
											label="반출상태"
											value={search.status}
											size={'small'}
											onChange={(e) => {
												setSearch({
													...search,
													status: e.target.value,
												})
											}}
										>
											<MenuItem value={'0'}>전체</MenuItem>
											<MenuItem
												value={'1'}
												sx={{ color: '#7f8c8d' }}
											>
												미신청
											</MenuItem>
											<MenuItem
												value={'2'}
												sx={{ color: '#f39c12' }}
											>
												반출신청
											</MenuItem>
											<MenuItem
												value={'3'}
												sx={{ color: '#27ae60' }}
											>
												반출중
											</MenuItem>
											<MenuItem
												value={'4'}
												sx={{ color: '#2980b9' }}
											>
												반출완료
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<FormControl fullWidth>
										<InputLabel id="searchType-select-label112">기간유형</InputLabel>
										<Select
											labelId="searchType-select-label112"
											id="searchType-simple-select112"
											label="기간유형"
											value={search.searchType}
											size={'small'}
											onChange={(e) => {
												setSearch({
													...search,
													searchType: e.target.value,
												})
											}}
										>
											<MenuItem value={'R'}>접수일</MenuItem>
											<MenuItem value={'D'}>신청일</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<DatePicker
										label="시작일"
										value={search.startDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'startDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<DatePicker
										label="종료일"
										value={search.endDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'endDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									{isLgUp ? (
										<>
											<FormControl sx={{ pt: 0.8 }}>
												<RadioGroup
													row
													aria-labelledby="date-controlled-radio-buttons-group"
													name="date-radio-buttons-group"
													value={dateRange}
													onChange={searchDateRanageOnChange}
													sx={{ ml: '0.5rem' }}
												>
													<FormControlLabel
														value="1d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="1일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
													<FormControlLabel
														value="3d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="3일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
													<FormControlLabel
														value="7d"
														control={<Radio sx={{ padding: '1px' }} />}
														label="7일"
														sx={{
															'& .MuiFormControlLabel-label': { fontSize: '12px' },
														}}
													/>
												</RadioGroup>
											</FormControl>
										</>
									) : (
										<>
											<FormControl fullWidth>
												<InputLabel id="date-range-select-label">조회 기간</InputLabel>
												<Select
													labelId="date-range-select-label"
													id="date-range-simple-select"
													value={dateRange}
													label="조회 기간"
													onChange={searchDateRanageOnChange}
													size={'small'}
												>
													<MenuItem value={'0'}>선택하세요.</MenuItem>
													<MenuItem value={'1d'}>1일</MenuItem>
													<MenuItem value={'3d'}>3일</MenuItem>
													<MenuItem value={'7d'}>7일</MenuItem>
												</Select>
											</FormControl>
										</>
									)}
								</Grid>
							</Grid>
						</SearchRowItem>
						<SearchRowItem>
							<p className="labelText">검색조건</p>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={6}
									sm={6}
									md={6}
									lg={4}
								>
									<TextField
										label="차트번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={search.chartNo}
										onChange={(e) => {
											setSearch({
												...search,
												chartNo: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={6}
									md={6}
									lg={4}
								>
									<TextField
										label="수진자명"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={search.patientName}
										onChange={(e) => {
											setSearch({
												...search,
												patientName: e.target.value,
											})
										}}
									/>
								</Grid>
								{/* <Grid
									item
									xs={6}
									sm={3}
									md={2}
									lg={2}
								>
									<TextField
										label="주민번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={search.socialNumber}
										onChange={(e) => {
											setSearch({
												...search,
												socialNumber: e.target.value,
											})
										}}
									/>
								</Grid> */}
								{/* <Grid
									item
									xs={6}
									sm={3}
									md={2}
									lg={2}
								>
									<TextField
										label="병리번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={search.pathologyNo}
										onChange={(e) => {
											setSearch({
												...search,
												pathologyNo: e.target.value,
											})
										}}
									/>
								</Grid> */}
								{(userInfo.infoType === 'S' || userInfo.infoType === 'W') && (
									<Grid
										item
										xs={6}
										sm={3}
										md={2}
									>
										<TextField
											label="거래처"
											variant="outlined"
											size="small"
											type="text"
											fullWidth
											aria-readonly={true}
											value={searchClientName}
											onClick={clientPopupOpenAction}
										/>
									</Grid>
								)}
							</Grid>
						</SearchRowItem>
					</LocalizationProvider>
				</Box>
			</Stack>
			<Paper
				elevation={0}
				sx={{ width: '100%', overflow: 'hidden', mt: 2 }}
			>
				<Grid container>
					<Grid
						item
						xs={12}
					>
						<PageBg>
							<Box
								sx={{
									height: 'calc(100vh - 300px)',
									'@media (max-width: 1199px)': {
										height: '400px',
										overflow: 'auto',
									},
								}}
							>
								<DataGridPremium
									rows={rows}
									apiRef={mstRef}
									getRowId={(row) => row.rowNum}
									experimentalFeatures={{ newEditingApi: true }}
									rowModesModel={rowModesModel}
									onRowEditStart={handleRowEditStart}
									onRowEditStop={handleRowEditStop}
									onCellEditStop={onCellEditStop}
									onStateChange={onStateChange}
									processRowUpdate={processRowUpdate}
									onSelectionModelChange={selectChangeHandler}
									selectionModel={selectionModel}
									disableMultipleSelection={true}
									columns={[
										{
											field: 'RowCrud',
											headerName: 'CRUD',
											type: 'string',
											minWidth: 50,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{RentalState}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{RentalState}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{RentalState}</span>
												}
											},
										},
										{
											field: 'ReceptionID',
											headerName: 'ID',
											type: 'number',
											width: 80,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState, ReceptionID } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{ReceptionID}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{ReceptionID}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{ReceptionID}</span>
												}
											},
										},
										{
											field: 'PartCode',
											headerName: '파트코드',
											type: 'string',
											width: 80,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState, PartCode } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{PartCode}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{PartCode}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{PartCode}</span>
												}
											},
										},
										{
											field: 'rowNum',
											headerName: '순번',
											type: 'number',
											width: 60,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState, rowNum } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{rowNum}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{rowNum}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{rowNum}</span>
												}
											},
										},
										{
											field: 'ClientName',
											headerName: '의뢰기관명',
											type: 'string',
											width: 100,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState, ClientName } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{ClientName}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{ClientName}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{ClientName}</span>
												}
											},
										},
										{
											field: 'ReceptionDate',
											headerName: '접수일자',
											type: 'string',
											width: 100,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState, ReceptionDate } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{ReceptionDate}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{ReceptionDate}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{ReceptionDate}</span>
												}
											},
										},
										{
											field: 'ReceptionRegNum',
											headerName: '접수번호',
											type: 'string',
											width: 100,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState, ReceptionRegNum } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{ReceptionRegNum}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{ReceptionRegNum}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{ReceptionRegNum}</span>
												}
											},
										},
										{
											field: 'PathologyNo',
											headerName: '병리번호',
											type: 'string',
											width: 150,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState, PathologyNo } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{PathologyNo}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{PathologyNo}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{PathologyNo}</span>
												}
											},
										},
										{
											field: 'PatientName',
											headerName: '수진자명',
											type: 'string',
											width: 100,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState, PatientName } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{PatientName}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{PatientName}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{PatientName}</span>
												}
											},
										},
										{
											field: 'ChartNo',
											headerName: '차트번호',
											type: 'string',
											width: 100,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState, ChartNo } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{ChartNo}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{ChartNo}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{ChartNo}</span>
												}
											},
										},
										{
											field: 'ClientInfo',
											headerName: '성별/나이',
											type: 'string',
											width: 85,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState, ClientInfo } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{ClientInfo}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{ClientInfo}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{ClientInfo}</span>
												}
											},
										},
										{
											field: 'TestCode',
											headerName: '검사코드',
											type: 'string',
											width: 90,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState, TestCode } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{TestCode}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{TestCode}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{TestCode}</span>
												}
											},
										},
										{
											field: 'DisplayName',
											headerName: '검사명',
											type: 'string',
											width: 220,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState, DisplayName } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{DisplayName}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{DisplayName}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{DisplayName}</span>
												}
											},
										},
										{
											field: 'SpecimenName',
											headerName: '검체명',
											type: 'string',
											width: 150,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState, SpecimenName } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{SpecimenName}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{SpecimenName}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{SpecimenName}</span>
												}
											},
										},
										{
											field: 'RentalState',
											headerName: '반출상태',
											type: 'string',
											width: 100,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { RentalState } = params.row
												if (RentalState === '반출신청') {
													return <span style={{ color: '#f39c12' }}>{RentalState}</span>
												} else if (RentalState === '반출중') {
													return <span style={{ color: '#27AE60' }}>{RentalState}</span>
												} else if (RentalState === '반출완료') {
													return <span style={{ color: '#2980b9' }}>{RentalState}</span>
												}
											},
										},
									]}
									pageSize={gridMstPageSize}
									onPageSizeChange={(newPageSize) => setGridMstPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
									pagination
									autoHeight={false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									loading={gridMstLoading}
									components={{
										Toolbar: GridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									componentsProps={{
										toolbar: {
											csvOptions: { disableToolbarButton: true },
											excelOptions: { disableToolbarButton: true },
											printOptions: { disableToolbarButton: true },
											showQuickFilter: false,
											quickFilterProps: { debounceMs: 250 },
										},
									}}
									density={(env.desnse as GridDensity) || 'compact'}
									initialState={{
										columns: {
											columnVisibilityModel: {
												RowCrud: false,
												ReceptionID: false,
												ClientName: false,
												PartCode: false,
											},
										},
									}}
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
								/>
							</Box>
						</PageBg>
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}

export default SbRequestPage
